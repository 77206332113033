import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { ComboBox } from '@syncfusion/ej2-dropdowns';
import { Template } from '@syncfusion/ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['actionFailureTemplate','allowCustom','allowFiltering','autofill','cssClass','dataSource','enablePersistence','enableRtl','enabled','fields','filterBarPlaceholder','filterType','floatLabelType','footerTemplate','groupTemplate','headerTemplate','htmlAttributes','ignoreAccent','ignoreCase','index','itemTemplate','locale','noRecordsTemplate','placeholder','popupHeight','popupWidth','query','readonly','showClearButton','sortOrder','text','value','valueTemplate','width','zIndex'];
export const /** @type {?} */ outputs: string[] = ['actionBegin','actionComplete','actionFailure','beforeOpen','blur','change','close','created','customValueSpecifier','dataBound','destroyed','filtering','focus','open','select','valueChange'];
export const /** @type {?} */ twoWays: string[] = ['value'];
/**
 * The ComboBox component allows the user to type a value or choose an option from the list of predefined options.
 * ```html
 * <ejs-combobox></ejs-combobox>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class ComboBoxComponent extends ComboBox implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	actionBegin: any;
	actionComplete: any;
	actionFailure: any;
	beforeOpen: any;
	blur: any;
	change: any;
	close: any;
	created: any;
	customValueSpecifier: any;
	dataBound: any;
	destroyed: any;
	filtering: any;
	focus: any;
	open: any;
	select: any;
public valueChange: any;
/**
 * Accepts the template design and assigns it to the footer container of the popup list. 
 * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
 * \@default null
 * @deprecated
 */
@Template()
    public footerTemplate: any;
/**
 * Accepts the template design and assigns it to the header container of the popup list. 
 * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
 * \@default null
 * @deprecated
 */
@Template()
    public headerTemplate: any;
/**
 * Accepts the template design and assigns it to the group headers present in the popup list.
 * \@default null
 * @deprecated
 */
@Template()
    public groupTemplate: any;
/**
 * Accepts the template design and assigns it to each list item present in the popup. 
 * We have built-in `template engine`
 * 
 * which provides options to compile template string into a executable function.
 * For EX: We have expression evolution as like ES6 expression string literals.
 *     
 * \@default null
 * @deprecated
 */
@Template()
    public itemTemplate: any;
    
    @Template('No records found')
public noRecordsTemplate: any;
    
    @Template('Request failed')
public actionFailureTemplate: any;
private skipFromEvent:boolean = true;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-combobox',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ComboBoxComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'footerTemplate': [{ type: ContentChild, args: ['footerTemplate', ] },],
'headerTemplate': [{ type: ContentChild, args: ['headerTemplate', ] },],
'groupTemplate': [{ type: ContentChild, args: ['groupTemplate', ] },],
'itemTemplate': [{ type: ContentChild, args: ['itemTemplate', ] },],
'noRecordsTemplate': [{ type: ContentChild, args: ['noRecordsTemplate', ] },],
'actionFailureTemplate': [{ type: ContentChild, args: ['actionFailureTemplate', ] },],
};
}

function ComboBoxComponent_tsickle_Closure_declarations() {
/** @type {?} */
ComboBoxComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ComboBoxComponent.ctorParameters;
/** @type {?} */
ComboBoxComponent.propDecorators;
/** @type {?} */
ComboBoxComponent.prototype.formCompContext;
/** @type {?} */
ComboBoxComponent.prototype.formContext;
/** @type {?} */
ComboBoxComponent.prototype.tagObjects;
/** @type {?} */
ComboBoxComponent.prototype.actionBegin;
/** @type {?} */
ComboBoxComponent.prototype.actionComplete;
/** @type {?} */
ComboBoxComponent.prototype.actionFailure;
/** @type {?} */
ComboBoxComponent.prototype.beforeOpen;
/** @type {?} */
ComboBoxComponent.prototype.blur;
/** @type {?} */
ComboBoxComponent.prototype.change;
/** @type {?} */
ComboBoxComponent.prototype.close;
/** @type {?} */
ComboBoxComponent.prototype.created;
/** @type {?} */
ComboBoxComponent.prototype.customValueSpecifier;
/** @type {?} */
ComboBoxComponent.prototype.dataBound;
/** @type {?} */
ComboBoxComponent.prototype.destroyed;
/** @type {?} */
ComboBoxComponent.prototype.filtering;
/** @type {?} */
ComboBoxComponent.prototype.focus;
/** @type {?} */
ComboBoxComponent.prototype.open;
/** @type {?} */
ComboBoxComponent.prototype.select;
/** @type {?} */
ComboBoxComponent.prototype.valueChange;
/**
 * Accepts the template design and assigns it to the footer container of the popup list. 
 * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
 * \@default null
 * @deprecated
 * @type {?}
 */
ComboBoxComponent.prototype.footerTemplate;
/**
 * Accepts the template design and assigns it to the header container of the popup list. 
 * > For more details about the available template options refer to [`Template`](../../drop-down-list/templates) documentation.
 * \@default null
 * @deprecated
 * @type {?}
 */
ComboBoxComponent.prototype.headerTemplate;
/**
 * Accepts the template design and assigns it to the group headers present in the popup list.
 * \@default null
 * @deprecated
 * @type {?}
 */
ComboBoxComponent.prototype.groupTemplate;
/**
 * Accepts the template design and assigns it to each list item present in the popup. 
 * We have built-in `template engine`
 * 
 * which provides options to compile template string into a executable function.
 * For EX: We have expression evolution as like ES6 expression string literals.
 *     
 * \@default null
 * @deprecated
 * @type {?}
 */
ComboBoxComponent.prototype.itemTemplate;
/** @type {?} */
ComboBoxComponent.prototype.noRecordsTemplate;
/** @type {?} */
ComboBoxComponent.prototype.actionFailureTemplate;
/** @type {?} */
ComboBoxComponent.prototype.skipFromEvent;
/** @type {?} */
ComboBoxComponent.prototype.registerEvents;
/** @type {?} */
ComboBoxComponent.prototype.addTwoWay;
/** @type {?} */
ComboBoxComponent.prototype.ngEle;
/** @type {?} */
ComboBoxComponent.prototype.srenderer;
/** @type {?} */
ComboBoxComponent.prototype.viewContainerRef;
/** @type {?} */
ComboBoxComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
