import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { DropDownTree } from '@syncfusion/ej2-dropdowns';
import { Template } from '@syncfusion/ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['actionFailureTemplate','allowFiltering','allowMultiSelection','changeOnBlur','cssClass','customTemplate','delimiterChar','enablePersistence','enableRtl','enabled','fields','filterBarPlaceholder','filterType','floatLabelType','footerTemplate','headerTemplate','htmlAttributes','ignoreAccent','ignoreCase','itemTemplate','locale','mode','noRecordsTemplate','placeholder','popupHeight','popupWidth','readonly','selectAllText','showCheckBox','showClearButton','showDropDownIcon','showSelectAll','sortOrder','text','treeSettings','unSelectAllText','value','width','wrapText','zIndex'];
export const /** @type {?} */ outputs: string[] = ['actionFailure','beforeOpen','blur','change','close','created','dataBound','destroyed','filtering','focus','keyPress','open','select','valueChange'];
export const /** @type {?} */ twoWays: string[] = ['value'];
/**
 * The DropDownTree component contains a list of predefined values from which you can choose a single or multiple values.
 * ```html
 * <ejs-dropdowntree></ejs-dropdowntree>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class DropDownTreeComponent extends DropDownTree implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	actionFailure: any;
	beforeOpen: any;
	blur: any;
	change: any;
	close: any;
	created: any;
	dataBound: any;
	destroyed: any;
	filtering: any;
	focus: any;
	keyPress: any;
	open: any;
	select: any;
public valueChange: any;
/**
 * Specifies the template that renders a customized footer container at the bottom of the pop-up list. 
 * By default, the footerTemplate will be null and there will be no footer container for the pop-up list.
 * \@default null
 */
@Template()
    public footerTemplate: any;
/**
 * Specifies the template that renders a customized header container at the top of the pop-up list. 
 * By default, the headerTemplate will be null and there will be no header container for the pop-up list.
 * \@default null
 */
@Template()
    public headerTemplate: any;
/**
 * Specifies a template to render customized content for all the items. 
 * If the **itemTemplate** property is set, the template content overrides the displayed item text. 
 * The property accepts [template string](https://ej2.syncfusion.com/documentation/common/template-engine/) 
 * or HTML element ID holding the content.
 * \@default null
 */
@Template()
    public itemTemplate: any;
    
    @Template('No records found')
public noRecordsTemplate: any;
    
    @Template('Request failed')
public actionFailureTemplate: any;
private skipFromEvent:boolean = true;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-dropdowntree',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DropDownTreeComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'footerTemplate': [{ type: ContentChild, args: ['footerTemplate', ] },],
'headerTemplate': [{ type: ContentChild, args: ['headerTemplate', ] },],
'itemTemplate': [{ type: ContentChild, args: ['itemTemplate', ] },],
'noRecordsTemplate': [{ type: ContentChild, args: ['noRecordsTemplate', ] },],
'actionFailureTemplate': [{ type: ContentChild, args: ['actionFailureTemplate', ] },],
};
}

function DropDownTreeComponent_tsickle_Closure_declarations() {
/** @type {?} */
DropDownTreeComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DropDownTreeComponent.ctorParameters;
/** @type {?} */
DropDownTreeComponent.propDecorators;
/** @type {?} */
DropDownTreeComponent.prototype.formCompContext;
/** @type {?} */
DropDownTreeComponent.prototype.formContext;
/** @type {?} */
DropDownTreeComponent.prototype.tagObjects;
/** @type {?} */
DropDownTreeComponent.prototype.actionFailure;
/** @type {?} */
DropDownTreeComponent.prototype.beforeOpen;
/** @type {?} */
DropDownTreeComponent.prototype.blur;
/** @type {?} */
DropDownTreeComponent.prototype.change;
/** @type {?} */
DropDownTreeComponent.prototype.close;
/** @type {?} */
DropDownTreeComponent.prototype.created;
/** @type {?} */
DropDownTreeComponent.prototype.dataBound;
/** @type {?} */
DropDownTreeComponent.prototype.destroyed;
/** @type {?} */
DropDownTreeComponent.prototype.filtering;
/** @type {?} */
DropDownTreeComponent.prototype.focus;
/** @type {?} */
DropDownTreeComponent.prototype.keyPress;
/** @type {?} */
DropDownTreeComponent.prototype.open;
/** @type {?} */
DropDownTreeComponent.prototype.select;
/** @type {?} */
DropDownTreeComponent.prototype.valueChange;
/**
 * Specifies the template that renders a customized footer container at the bottom of the pop-up list. 
 * By default, the footerTemplate will be null and there will be no footer container for the pop-up list.
 * \@default null
 * @type {?}
 */
DropDownTreeComponent.prototype.footerTemplate;
/**
 * Specifies the template that renders a customized header container at the top of the pop-up list. 
 * By default, the headerTemplate will be null and there will be no header container for the pop-up list.
 * \@default null
 * @type {?}
 */
DropDownTreeComponent.prototype.headerTemplate;
/**
 * Specifies a template to render customized content for all the items. 
 * If the **itemTemplate** property is set, the template content overrides the displayed item text. 
 * The property accepts [template string](https://ej2.syncfusion.com/documentation/common/template-engine/) 
 * or HTML element ID holding the content.
 * \@default null
 * @type {?}
 */
DropDownTreeComponent.prototype.itemTemplate;
/** @type {?} */
DropDownTreeComponent.prototype.noRecordsTemplate;
/** @type {?} */
DropDownTreeComponent.prototype.actionFailureTemplate;
/** @type {?} */
DropDownTreeComponent.prototype.skipFromEvent;
/** @type {?} */
DropDownTreeComponent.prototype.registerEvents;
/** @type {?} */
DropDownTreeComponent.prototype.addTwoWay;
/** @type {?} */
DropDownTreeComponent.prototype.ngEle;
/** @type {?} */
DropDownTreeComponent.prototype.srenderer;
/** @type {?} */
DropDownTreeComponent.prototype.viewContainerRef;
/** @type {?} */
DropDownTreeComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
