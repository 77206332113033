import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DropDownTreeComponent } from './dropdowntree.component';
import { DropDownTreeModule } from './dropdowntree.module';
/**
 * NgModule definition for the DropDownTree component with providers.
 */
export class DropDownTreeAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, DropDownTreeModule],
    exports: [
        DropDownTreeModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function DropDownTreeAllModule_tsickle_Closure_declarations() {
/** @type {?} */
DropDownTreeAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
DropDownTreeAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
