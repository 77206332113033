import { Component, ElementRef, ViewContainerRef, ValueProvider, Renderer2, Injector, ChangeDetectionStrategy, forwardRef, ContentChild } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { ComponentBase, IComponentBase, applyMixins, ComponentMixins, PropertyCollectionInfo, FormBase, setValue } from '@syncfusion/ej2-angular-base';
import { ListBox } from '@syncfusion/ej2-dropdowns';
import { Template } from '@syncfusion/ej2-angular-base';


export const /** @type {?} */ inputs: string[] = ['actionFailureTemplate','allowDragAndDrop','allowFiltering','cssClass','dataSource','enablePersistence','enableRtl','enabled','fields','filterBarPlaceholder','filterType','groupTemplate','height','ignoreAccent','ignoreCase','itemTemplate','locale','maximumSelectionLength','noRecordsTemplate','query','scope','selectionSettings','sortOrder','toolbarSettings','value','zIndex'];
export const /** @type {?} */ outputs: string[] = ['focus', 'blur', 'actionBegin','actionComplete','actionFailure','beforeDrop','beforeItemRender','change','created','dataBound','destroyed','drag','dragStart','drop','filtering','select','valueChange'];
export const /** @type {?} */ twoWays: string[] = ['value'];
/**
 * The ListBox allows the user to select values from the predefined list of values.
 * ```html
 * <ejs-listbox [dataSource]='data'></ejs-listbox>
 * ```
 */
@ComponentMixins([ComponentBase, FormBase])
export class ListBoxComponent extends ListBox implements IComponentBase {
public formCompContext : any;
public formContext : any;
public tagObjects: any;
	actionBegin: any;
	actionComplete: any;
	actionFailure: any;
	beforeDrop: any;
	beforeItemRender: any;
	change: any;
	created: any;
	dataBound: any;
	destroyed: any;
	drag: any;
	dragStart: any;
	drop: any;
	filtering: any;
	select: any;
public valueChange: any;
/**
 * Accepts the template design and assigns it to each list item present in the popup. 
 * We have built-in `template engine`
 * 
 * which provides options to compile template string into a executable function.
 * For EX: We have expression evolution as like ES6 expression string literals.
 *     
 * \@default null
 * @deprecated
 */
@Template()
    public itemTemplate: any;
    
    @Template('No records found')
public noRecordsTemplate: any;
public focus: any;
public blur: any;
/**
 * @param {?} ngEle
 * @param {?} srenderer
 * @param {?} viewContainerRef
 * @param {?} injector
 */
constructor(private ngEle: ElementRef,
private srenderer: Renderer2,
private viewContainerRef:ViewContainerRef,
private injector: Injector) {
        super();
        this.element = this.ngEle.nativeElement;
        this.injectedModules = this.injectedModules || [];
        try {
                let mod = this.injector.get('DropDownsCheckBoxSelection');
                if(this.injectedModules.indexOf(mod) === -1) {
                    this.injectedModules.push(mod)
                }
            } catch { }

        this.registerEvents(outputs);
        this.addTwoWay.call(this, twoWays);
        setValue('currentInstance', this, this.viewContainerRef);
        this.formContext  = new FormBase();
        this.formCompContext  = new ComponentBase();
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnChange(registerFunction: (_: any) => void): void {
    }
/**
 * @param {?} registerFunction
 * @return {?}
 */
public registerOnTouched(registerFunction: () => void): void {
    }
/**
 * @param {?} value
 * @return {?}
 */
public writeValue(value: any): void {
    }
/**
 * @param {?} disabled
 * @return {?}
 */
public setDisabledState(disabled: boolean): void {
    }
/**
 * @return {?}
 */
public ngOnInit() {
        this.formCompContext.ngOnInit(this);
    }
/**
 * @return {?}
 */
public ngAfterViewInit(): void {
        this.formContext.ngAfterViewInit(this);
    }
/**
 * @return {?}
 */
public ngOnDestroy(): void {
        this.formCompContext.ngOnDestroy(this);
    }
/**
 * @return {?}
 */
public ngAfterContentChecked(): void {
        
        this.formCompContext.ngAfterContentChecked(this);
    }
public registerEvents: (eventList: string[]) => void;
public addTwoWay: (propList: string[]) => void;
static decorators: DecoratorInvocation[] = [
{ type: Component, args: [{
    selector: 'ejs-listbox',
    inputs: inputs,
    outputs: outputs,
    template: '',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ListBoxComponent),
            multi: true
        }
    ],
    queries: {

    }
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
{type: ElementRef, },
{type: Renderer2, },
{type: ViewContainerRef, },
{type: Injector, },
];
static propDecorators: {[key: string]: DecoratorInvocation[]} = {
'itemTemplate': [{ type: ContentChild, args: ['itemTemplate', ] },],
'noRecordsTemplate': [{ type: ContentChild, args: ['noRecordsTemplate', ] },],
};
}

function ListBoxComponent_tsickle_Closure_declarations() {
/** @type {?} */
ListBoxComponent.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ListBoxComponent.ctorParameters;
/** @type {?} */
ListBoxComponent.propDecorators;
/** @type {?} */
ListBoxComponent.prototype.formCompContext;
/** @type {?} */
ListBoxComponent.prototype.formContext;
/** @type {?} */
ListBoxComponent.prototype.tagObjects;
/** @type {?} */
ListBoxComponent.prototype.actionBegin;
/** @type {?} */
ListBoxComponent.prototype.actionComplete;
/** @type {?} */
ListBoxComponent.prototype.actionFailure;
/** @type {?} */
ListBoxComponent.prototype.beforeDrop;
/** @type {?} */
ListBoxComponent.prototype.beforeItemRender;
/** @type {?} */
ListBoxComponent.prototype.change;
/** @type {?} */
ListBoxComponent.prototype.created;
/** @type {?} */
ListBoxComponent.prototype.dataBound;
/** @type {?} */
ListBoxComponent.prototype.destroyed;
/** @type {?} */
ListBoxComponent.prototype.drag;
/** @type {?} */
ListBoxComponent.prototype.dragStart;
/** @type {?} */
ListBoxComponent.prototype.drop;
/** @type {?} */
ListBoxComponent.prototype.filtering;
/** @type {?} */
ListBoxComponent.prototype.select;
/** @type {?} */
ListBoxComponent.prototype.valueChange;
/**
 * Accepts the template design and assigns it to each list item present in the popup. 
 * We have built-in `template engine`
 * 
 * which provides options to compile template string into a executable function.
 * For EX: We have expression evolution as like ES6 expression string literals.
 *     
 * \@default null
 * @deprecated
 * @type {?}
 */
ListBoxComponent.prototype.itemTemplate;
/** @type {?} */
ListBoxComponent.prototype.noRecordsTemplate;
/** @type {?} */
ListBoxComponent.prototype.focus;
/** @type {?} */
ListBoxComponent.prototype.blur;
/** @type {?} */
ListBoxComponent.prototype.registerEvents;
/** @type {?} */
ListBoxComponent.prototype.addTwoWay;
/** @type {?} */
ListBoxComponent.prototype.ngEle;
/** @type {?} */
ListBoxComponent.prototype.srenderer;
/** @type {?} */
ListBoxComponent.prototype.viewContainerRef;
/** @type {?} */
ListBoxComponent.prototype.injector;
}



interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
