import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxComponent } from './combobox.component';
/**
 * NgModule definition for the ComboBox component.
 */
export class ComboBoxModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule],
    declarations: [
        ComboBoxComponent
    ],
    exports: [
        ComboBoxComponent
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ComboBoxModule_tsickle_Closure_declarations() {
/** @type {?} */
ComboBoxModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ComboBoxModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
