import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComboBoxComponent } from './combobox.component';
import { ComboBoxModule } from './combobox.module';
/**
 * NgModule definition for the ComboBox component with providers.
 */
export class ComboBoxAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, ComboBoxModule],
    exports: [
        ComboBoxModule
    ],
    providers:[
        
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function ComboBoxAllModule_tsickle_Closure_declarations() {
/** @type {?} */
ComboBoxAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
ComboBoxAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
