import { NgModule, ValueProvider } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MultiSelectComponent } from './multiselect.component';
import { MultiSelectModule } from './multiselect.module';
import {CheckBoxSelection} from '@syncfusion/ej2-dropdowns';


export const /** @type {?} */ CheckBoxSelectionService: ValueProvider = { provide: 'DropDownsCheckBoxSelection', useValue: CheckBoxSelection};
/**
 * NgModule definition for the MultiSelect component with providers.
 */
export class MultiSelectAllModule { static decorators: DecoratorInvocation[] = [
{ type: NgModule, args: [{
    imports: [CommonModule, MultiSelectModule],
    exports: [
        MultiSelectModule
    ],
    providers:[
        CheckBoxSelectionService
    ]
}, ] },
];
/**
 * @nocollapse
 */
static ctorParameters: () => ({type: any, decorators?: DecoratorInvocation[]}|null)[] = () => [
];
}

function MultiSelectAllModule_tsickle_Closure_declarations() {
/** @type {?} */
MultiSelectAllModule.decorators;
/**
 * @nocollapse
 * @type {?}
 */
MultiSelectAllModule.ctorParameters;
}

interface DecoratorInvocation {
  type: Function;
  args?: any[];
}
